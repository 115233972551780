/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Lo primero en nuestro kde4 limpio es instalar una serie de codecs y programas basicos. Estos primeros son practicamente esenciales: Ark: Herramienta de archivación para KDE Rar y unrar: codecs de rar que utiliza ark flashplugin: Codec de flash para tu navegador."), "\n", React.createElement(_components.p, null, "Los instalamos:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">$ aptitude install ark rar unrar flashplugin-nonfree flashplugin-nonfree-extrasound</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "Los siguientes programas ya son a gusto de cada uno."), "\n", React.createElement(_components.p, null, "GUI de apt: Synaptic Reproductor de música: Amarok Reproductor de vídeo: Vlc Cliente de mensajería instantanea: Amsn Office: Openoffice Cliente de correo: Icedove Wine Visor de imagenes: Gwenview Editor de imagenes: Gimp"), "\n", React.createElement(_components.p, null, "Y los podemos instalar todos de una:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">aptitude install synaptic amarok vlc amsn openoffice icedove wine gwenview gimp</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "Con todo esto ya podemos empezar a trabajar un poco. Me falta instalar adobe reader, pero como no está en los repositorios lo explicare en otro post."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
